<template>
  <div>
    <a target="_blank" :href="$isBlackFriday ? $linkBlackFridayWhats : $checkoutAnual" rel="noopener noreferrer">
      <span v-if="!$isBlackFriday">
        <img v-if="banner === 1" class="w-100 pointer with-events" src="@/assets/images/banner-free.png" />
        <img v-if="banner === 2" class="w-100 pointer with-events" src="@/assets/images/banner-free2.png" />
        <img v-if="banner === 3" class="w-100 pointer with-events" src="@/assets/images/banner-free3.png" />
        <img v-if="banner === 4" class="w-100 pointer with-events" src="@/assets/images/banner-free4.png" />
        <img v-if="banner === 5" class="w-100 pointer with-events" src="@/assets/images/banner-free5.png" />
      </span>

      <span v-else-if="$isBlackFridayStarted">
        <img v-if="banner === 1" class="w-100 pointer with-events"
          src="@/assets/images/blackfriday-started/banner-free.png" />
        <img v-if="banner === 2" class="w-100 pointer with-events"
          src="@/assets/images/blackfriday-started/banner-free2.png" />
        <img v-if="banner === 3" class="w-100 pointer with-events"
          src="@/assets/images/blackfriday-started/banner-free3.png" />
        <img v-if="banner === 4" class="w-100 pointer with-events"
          src="@/assets/images/blackfriday-started/banner-free4.png" />
        <img v-if="banner === 5" class="w-100 pointer with-events"
          src="@/assets/images/blackfriday-started/banner-free5.png" />
      </span>

      <span v-else>
        <img v-if="banner === 1" class="w-100 pointer with-events" src="@/assets/images/blackfriday/banner-free.png" />
        <img v-if="banner === 2" class="w-100 pointer with-events" src="@/assets/images/blackfriday/banner-free2.png" />
        <img v-if="banner === 3" class="w-100 pointer with-events" src="@/assets/images/blackfriday/banner-free3.png" />
        <img v-if="banner === 4" class="w-100 pointer with-events" src="@/assets/images/blackfriday/banner-free4.png" />
        <img v-if="banner === 5" class="w-100 pointer with-events" src="@/assets/images/blackfriday/banner-free5.png" />
      </span>
    </a>
  </div>
</template>

<script>
export default {
  name: "PropagandaComp",

  props: {
    banner: {
      default: 1,
    },
  },
};
</script>

<style lang="sass" scoped>
</style>
